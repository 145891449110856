import { getCompanyDetails, getCompanyLogo } from 'api/company';
import { useQuery } from 'react-query';

export const useCompanyLogo = () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useQuery<string>('companyLogo', async () => await getCompanyLogo());

export const useCompanyDetails = () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useQuery<any>('company', async () => await getCompanyDetails());
