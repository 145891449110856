import Text from 'antd/lib/typography/Text';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './styles.module.less';

const ArrimoText = ({ children, className = '', ...props }) => (
  <Text className={`${styles.arrimo_text} ${className}`} {...props}>
    {children}
  </Text>
);

export default ArrimoText;
