import { Form } from 'antd';
import { useTranslations } from 'hooks/translations/useTranslations';
import * as yup from 'yup';

import { AlignContent, ArrimoButton, ArrimoLink, ArrimoText } from 'atoms';
import { FormInputMui } from 'components/library';

import { Stack } from '@mui/material';
import { useFormikContext, withFormik } from 'formik';
import AccountWrapper from './AccountWrapper';
import { withAccount } from './containers/AccountContainer';

const Login = () => {
  const {
    account: { t },
  } = useTranslations();
  const formik = useFormikContext();

  const { text, link } = style;

  return (
    <AccountWrapper>
      <Form>
        <Stack spacing={3} direction="column">
          <AlignContent align="center">
            <ArrimoText style={text}>{t('sign_in')}</ArrimoText>
          </AlignContent>
          <FormInputMui
            name="email"
            label={t('email')}
            formik={formik}
            fullWidth
          />
          <FormInputMui
            name="password"
            label={t('password')}
            formik={formik}
            fullWidth
            type="password"
          />
          <AlignContent align="right">
            <ArrimoLink
              style={link}
              href="/recovery"
              text={t('forgot_password')}
            />
          </AlignContent>
          <ArrimoButton
            loading={formik.isSubmitting}
            fullWidth
            data-testid="login"
            onClick={formik.submitForm}
            type="submit"
          >
            {t('btn_sign_in')}
          </ArrimoButton>
        </Stack>
      </Form>
    </AccountWrapper>
  );
};

const style = {
  link: {
    width: 110,
  },
  text: { width: 104, height: 38, fontSize: 30 },
};

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Required'),
  password: yup.string().required('Required'),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default withAccount<any>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  withFormik<any, any, any>({
    mapPropsToValues: () => ({ email: '', password: '' }),
    validationSchema,
    validateOnChange: false,
    handleSubmit: (values, { props, setSubmitting }) => {
      const { login } = props;
      login(values, () => {
        setSubmitting(false);
      });
    },
  })(Login)
);
