import { CardActions, CardContent, Typography } from '@mui/material';
import Card from '@mui/material/Card';

import ArrimoButton from 'atoms/ArrimoButton';
import { useTranslations } from 'hooks/translations/useTranslations';

const EntityDeleteCards = ({
  entity = {},
  onDelete,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entity: any;
  onDelete: () => void;
}) => {
  const {
    common: { t },
  } = useTranslations();

  return (
    <Card sx={{ borderRadius: 4 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {entity.firstName || entity.designation || entity.licensePlate}
        </Typography>
        <Typography variant="h6">{entity.lastName}</Typography>
      </CardContent>
      <CardActions>
        <ArrimoButton color="error" onClick={onDelete}>
          {t('delete')}
        </ArrimoButton>
      </CardActions>
    </Card>
  );
};

export default EntityDeleteCards;
