type AlignContentProps = {
  children: React.ReactNode;
  align: 'left' | 'center' | 'right';
  style?: React.CSSProperties;
};

const AlignContent = ({ children, align, style }: AlignContentProps) => (
  <div style={{ textAlign: align, ...(style || {}) }}>{children}</div>
);

export default AlignContent;
