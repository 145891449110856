import Head from 'next/head';

import { Col, Layout, Row } from 'antd';

import SwitchLanguage from 'molecules/SwitchLanguage';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from 'assets/account/layout.module.less';

const { Content } = Layout;

const AccountPage = ({ children }) => (
  <div>
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
        key="viewport"
      />
      <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
    </Head>
    <Layout>
      <Layout>
        <Content style={{ backgroundColor: '#fff', padding: 20 }}>
          <Row style={{ justifyContent: 'end' }}>
            <Col style={{ backgroundColor: '#fff' }} md={6} xs={8}>
              <SwitchLanguage />
            </Col>

            <Col className={styles.form_box}>
              <Row justify="center" align="middle" style={{ height: '100%' }}>
                {children}
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  </div>
);

export default AccountPage;
