import { Button } from 'antd';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './styles.module.less';

const HomeButton = ({ children, className = '', ...props }) => (
  <Button
    className={`${styles.home_button} ${className}`}
    style={{ margin: '0 auto' }}
    {...props}
  >
    {children}
  </Button>
);

export default HomeButton;
