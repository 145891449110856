import { Input } from 'antd';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './styles.module.less';

const ArrimoInput = ({ className, ...props }) => (
  <Input className={`${styles.arrimo_input} ${className}`} {...props} />
);

export default ArrimoInput;
