import { memo } from 'react';

import { useCompanyLogo } from 'queries/useCompany';
import { Box } from '@mui/material';

const AccountWrapper = ({ children }) => {
  const { data: companyLogo } = useCompanyLogo();
  return (
    <Box width={480} textAlign="center">
      {/* eslint-disable-next-line @next/next/no-img-element*/}
      <img
        src={companyLogo || '../static/logo-with-text.png'}
        alt="logo"
        style={{
          margin: '0 auto',
          maxWidth: 480,
          objectFit: 'contain',
        }}
      />
      {children}
    </Box>
  );
};

export default memo(AccountWrapper);
