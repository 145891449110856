import { Check, Delete, Download } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Col, Row } from 'antd';
import ArrimoButton from 'atoms/ArrimoButton';
import { useTranslations } from 'hooks/translations/useTranslations';

interface SubmitButtonFormProps {
  onDelete: () => void;
  onPDF?: () => void;
  onServiceAgreementPDF?: () => Promise<void>;
  pdfButtonText?: string;
  isPatients?: boolean;
  loading?: boolean;
  isLoadingServiceAgreementPDF?: boolean;
}

const SubmitButtonForm = ({
  onDelete,
  onPDF,
  onServiceAgreementPDF,
  pdfButtonText,
  isPatients = false,
  loading = false,
  isLoadingServiceAgreementPDF,
}: SubmitButtonFormProps) => {
  const {
    common: { t },
  } = useTranslations();

  if (isPatients) {
    return (
      <div style={{ marginTop: 40 }}>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col md={4}>
            <ArrimoButton
              variant="outlined"
              color="error"
              onClick={onDelete}
              startIcon={<Delete />}
              size="medium"
            >
              {t('delete')}
            </ArrimoButton>
          </Col>
          <Col md={6}>
            <ArrimoButton
              variant="contained"
              color="primary"
              onClick={onPDF}
              size="medium"
              startIcon={<Download />}
            >
              {pdfButtonText || t('safePDF')}
            </ArrimoButton>
          </Col>
          <Col md={9}>
            <ArrimoButton
              variant="contained"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              color="orange"
              onClick={onServiceAgreementPDF}
              loading={isLoadingServiceAgreementPDF}
              size="medium"
              startIcon={<Download />}
            >
              {t('service_agreement_pdf')}
            </ArrimoButton>
          </Col>
          <Col md={4} style={{ textAlign: 'end' }}>
            <ArrimoButton
              variant="contained"
              color="success"
              loading={loading}
              type="submit"
              size="medium"
              startIcon={<Check />}
            >
              {t('save')}
            </ArrimoButton>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      style={{ marginTop: 40 }}
    >
      <ArrimoButton
        variant="outlined"
        color="error"
        onClick={onDelete}
        startIcon={<Delete />}
      >
        {t('delete')}
      </ArrimoButton>
      <ArrimoButton
        variant="contained"
        color="success"
        loading={loading}
        type="submit"
        startIcon={<Check />}
      >
        {t('save')}
      </ArrimoButton>
    </Stack>
  );
};

export default SubmitButtonForm;
