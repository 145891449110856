// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './styles.module.less';

const ArrimoLink = ({ text = '', className = '', ...props }) => (
  <a className={`${styles.arrimo_link} ${className}`} {...props}>
    {text}
  </a>
);
export default ArrimoLink;
